import React from 'react';
import './HomeContact.css';
import callImg from "../../../Images/contact-icon/contact-call.svg";
import emailImg from "../../../Images/contact-icon/contact-call.svg";
import socialImg from "../../../Images/contact-icon/contact-social-logo.svg";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const HomeContact = ({ home }) => {
    return (
        <div className="container-fluid home-contact-container pt-5">
            <div className="container ">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-md-7 col-lg-8  ">
                        <button className='contact-btn'>{home?.contact?.sub_title}</button>
                        <h1 className='home-contact-title'>{home?.contact?.title}</h1>
                        {/* <p className='home-contact-sub-text'>{home?.contact?.description}</p> */}

                    </div>
                    {/* <div className="col-12 col-sm-12 col-md-5 col-lg-4 pb-3 text-lg-end">
                        <button className='send-message-btn'>{home?.contact?.button_text}</button>
                    </div> */}


                </div>

                <div className="row align-items-center py-5">

                    <div className="col-12 col-sm-12 col-md-6 col-lg-5 ">
                        {/* email info */}
                        <div className="contact-email-info d-flex pb-5">
                            <div className='me-4'>
                                <img src={callImg} alt="" className='img-fluid' width={"48"} />
                            </div>
                            <div>
                                <h1 className='contact-email-title'>Email</h1>
                                <p className='contact-email-txt'>{home?.email}</p>
                            </div>
                        </div>

                        {/* phone info */}
                        <div className="contact-phone-info d-flex pb-5">
                            <div className='me-4'>
                                <img src={callImg} alt="" className='img-fluid' width={"48"} />
                            </div>
                            <div>
                                <h1 className='contact-phone-title'>Phone</h1>
                                <p className='contact-phone-txt'>{home?.phone}</p>
                            </div>
                        </div>

                        {/* Socials info */}
                        <div className="contact-socials-info d-flex pb-5">
                            <div className='me-4'>
                                <img src={socialImg} alt="" className='img-fluid' width={"48"} />
                            </div>
                            <div>
                                <h1 className='contact-socials-title'>{home?.socials}</h1>
                                <div className='social-media-holder'>
                                    <a href={home?.facebook_url} target={"_blank"} rel="noreferrer" >
                                        <FaFacebookF className='contact-social-icon me-4'></FaFacebookF>
                                    </a>
                                    <a href={home?.instagram_url} target={"_blank"} rel="noreferrer" >
                                        <FaInstagram className='contact-social-icon me-4'></FaInstagram>
                                    </a>
                                    <a href={home?.linkedin_url} target={"_blank"} rel="noreferrer" >
                                        <FaLinkedin className='contact-social-icon me-4'></FaLinkedin>
                                    </a>
                                    <a href={home?.twitter_url} target={"_blank"} rel="noreferrer">
                                        <FaTwitter className='contact-social-icon'></FaTwitter>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-7  ">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d645.2602995444881!2d90.41478718894092!3d23.813496868376085!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7b0b2f8bfcb%3A0x7b65316d82a646a2!2sFrame!5e0!3m2!1sen!2sbd!4v1652601973393!5m2!1sen!2sbd" height="350" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='contact-map' title='map'></iframe>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default HomeContact;