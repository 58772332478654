import React, { useEffect, useState } from 'react';
import './HomeVision.css';
import VisionItemCard from './VisionItem/VisionItemCard';

const HomeVision = ({ home }) => {



    return (
        <div className="container-fluid home-vision-container py-5">
            <div className="container ">
                <div className='text-center'>
                    <button className='vission-btn'>{home?.vision?.sub_title}</button>
                    <h1 className='home-vision-title '>{home?.vision?.title}</h1>
                    <p className='home-vision-sub-txt px-lg-5 mx-lg-5'>{home?.vision?.description}</p>
                </div>
                <div className="row mx-auto align-items-center">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 ">
                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1 g-4">
                            {
                                home?.vision?.vision_description?.map((item, index) => {
                                    return index % 2 === 0 && <VisionItemCard
                                        key={item.id}
                                        item={item}></VisionItemCard>
                                })
                            }
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4  p-3">
                        <img src={home?.vision?.image_url} alt="" className='img-fluid' width={"358"} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4  ">
                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1 g-4 align-items-center">
                            {
                                home?.vision?.vision_description?.map((item, index) => {
                                    return index % 2 !== 0 && <VisionItemCard
                                        key={item.id}
                                        item={item}></VisionItemCard>
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default HomeVision;