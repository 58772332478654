import React from 'react';
import ClientCard from './ClientCard/ClientCard';
import './OutClient.css';

const OurClient = ({ home }) => {
    return (
        <div className="container-fluid home-client-container py-5" id="client">
            <div className="container ">
                <div className="team-top-content-container">
                    <button className='client-btn'>{home?.client?.sub_title}</button>
                    <h1 className='home-client-title'>{home?.client?.title}</h1>
                    {/* <p className='home-client-sub-text'>{home?.client?.description}</p> */}
                    <img src={home?.client?.image_url} alt="" className='img-fluid pt-5' />

                </div>

                {/* <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 align-items-center py-5">

                    {home?.client?.client_description?.map((client, index) => <ClientCard
                        key={client.id}
                        client={client}
                    ></ClientCard>)}


                </div> */}
            </div>
        </div>
    );
};

export default OurClient;