import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../../../Images/logo.png';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';
import Swal from 'sweetalert2';

const Footer = ({ home }) => {

    const [email, setEmail] = useState("");

    const subscribeEmailHandle = () => {
        // console.log({ email: email })
        axios.post('https://framestaging.techsistltd.com/subscribe_news_letter/', {
            email: email,

        })
            .then(function (response) {
                console.log(response);
                if (response.data.status) {
                    setEmail("");
                    Swal.fire(
                        'Thank You!!',
                        'Welcome to Frame',
                        'success'
                    )
                }
                else {
                    alert(response.data.msg);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="container-fluid footer-container py-5">
            <div className="container ">
                <div className="row align-items-center py-5  ">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6  ">

                        <h1 className='footer-brand-title pb-3'>
                            <img src={home?.logo_url} alt="" className='img-fluid' width={"80"} />
                        </h1>

                        <div className="footer-menu">
                            <Link to="#about" className="footer-menu-item" >About</Link>
                            <Link as={HashLink} to="/home#departments" className="footer-menu-item " >Services</Link>
                            <Link as={HashLink} to="/home#team" className="footer-menu-item " >Team</Link>
                            <Link as={HashLink} to="/home#client" className="footer-menu-item " >Clients</Link>

                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 pb-3 text-lg-end py-3">
                        <input type="email" placeholder='Your Subscribe Email' className='subscribe-input me-lg-3 mb-3'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                        <button className='subscribe-btn' onClick={() => subscribeEmailHandle()}>Subscribe</button>
                    </div>


                </div>


                <div className="row align-items-center py-5 border-top border-secondary">

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                        <p className='copyright-txt'>Copyright © 2022. Techsist. All rights reserved.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-lg-end ">


                        <div className="footer-menu-2 py-2">
                            <Link to="#terms" className="footer-menu-item-2 ps-lg-5 " >Terms</Link>
                            <Link to="#privacy" className="footer-menu-item-2  ps-lg-5 " >Privacy</Link>
                            <Link to="#cookies" className="footer-menu-item-2  ps-lg-5 " >Cookies</Link>

                        </div>
                    </div>


                </div>

            </div>
        </div>
    );
};

export default Footer;