import axios from 'axios';
import React, { useId } from 'react';
import { Accordion } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import './JobCirculer.css';

const JobCirculer = ({ circular }) => {
    console.log(circular);
    const id = useId();

    const { register, handleSubmit, reset } = useForm();

    const onSubmit = data => {


        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("file", data.job_circular[0]);
        formData.append("body", data.body);
        formData.append("job_circular", circular.career);

        axios.post('https://framestaging.techsistltd.com/drop_cv/', formData)
            .then(function (response) {
                // console.log(response);
                if (response.data.status) {
                    Swal.fire(
                        'Thanks for Apply!',
                        'We will notify soon!',
                        'success'
                    )
                    reset();
                }
                else {
                    alert(response.data.msg)
                }



            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <Accordion.Item eventKey={id}>

            <Accordion.Header >
                <h1 className='job-position-title py-3'>{circular?.title}</h1>
            </Accordion.Header>
            <Accordion.Body>
                <div className="job-description py-3" dangerouslySetInnerHTML={{ __html: circular?.description }} />

                <form className="row g-3 form-input-txt shadow-sm py-3 " onSubmit={handleSubmit(onSubmit)}>

                    <div className="col-md-6">
                        <div className="form-floating ">
                            <input {...register("name")} type="text" className="form-control border border-success" id="name" placeholder="Your name" />
                            <label htmlFor="name" className='form-input-txt'>Your name</label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-floating ">
                            <input {...register("email")} type="email" className="form-control border border-success" id="email" placeholder="name@example.com" />
                            <label htmlFor="email" className='form-input-txt'>Email address</label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-floating">
                            <input {...register("phone")} type="number" className="form-control border border-success" id="number" placeholder="number" />
                            <label htmlFor="number" className='form-input-txt'>Phone Number</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating">
                            <input {...register("job_circular")} type="file" className="form-control border border-success" id="number" placeholder="number" />
                            <label htmlFor="number" className='form-input-txt'>Your CV</label>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-floating">
                            <textarea {...register("body")} type="text" className="form-control border border-success" id="aboutYourself" placeholder="about Yourself" style={{ height: "100px" }} />
                            <label htmlFor="aboutYourself" className='form-input-txt'>Write about yourself</label>
                        </div>
                    </div>
                    <div className="col-12">
                        <input type="submit" className='career-submit-btn' value={"Apply"} />
                    </div>

                </form>

            </Accordion.Body>
        </Accordion.Item>
    );
};

export default JobCirculer;