import React from 'react';
import './TeamCard.css';
import teamLogo from "../../../../Images/team-img.svg";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";

const TeamCard = ({ team }) => {

    return (
        <div className='col'>

            <div className="team-item-card h-100 p-3">
                <img src={team?.image_url} alt="" className='img-fluid mx-auto py-4 team-img' width={"88"} />
                <div className="team-card-body">

                    <div className='h-50'>
                        <h5 className="team-card-title m-0">{team?.title} </h5>
                    </div>
                    <p className="team-card-designation-text m-0">{team?.designation}</p>
                    {/* <p className="team-card-bio-text">{team?.description}</p> */}


                </div>
                <div className='team-card-footer social-media-holder'>
                    <a href={team?.facebook_url} target={"_blank"} rel="noreferrer" >
                        <FaFacebookF className='social-icon me-4'></FaFacebookF>
                    </a>
                    <a href={team?.twitter_url} target={"_blank"} rel="noreferrer">
                        <FaLinkedinIn className='social-icon'></FaLinkedinIn>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TeamCard;