import React from 'react';
import './HomeBanner.css';
import homeBannerImg from "../../../Images/home-banner-img/home-banner-bg.svg";

const HomeBanner = ({ home }) => {


    return (
        <div className='container-fluid home-banner-container py-5' id='about'>
            <div className="container ">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 pb-3">
                        <h1 className='home-banner-title'>{home?.slider?.title} </h1>
                        <p className='home-banner-sub-text'>{home?.slider?.description}</p>
                        <button className='our-service-btn'>{home?.slider?.button_text}</button>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                        <img src={home?.slider?.file_url} alt="" className='img-fluid ms-lg-auto' width={"554"} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeBanner;