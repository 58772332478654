import { Route, Routes } from 'react-router-dom';
import './App.css';
import Career from './Pages/Home/Career/Career';
import Home from './Pages/Home/Home';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/career" element={<Career />} />

      </Routes>

    </div>
  );
}

export default App;
