import React from 'react';
import departmentsImg from "../../../Images/departments-img.svg";
import HomeDepartmentsCard from './HomeDepartmentsCard/HomeDepartmentsCard';
import './HomeDepartments.css';

const HomeDepartments = ({ home }) => {
    return (
        <div className="container-fluid home-departments-container py-5" id='departments'>
            <div className="container ">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6  ">
                        <button className='department-btn'>{home?.department?.sub_title}</button>
                        <h1 className='home-department-title'>{home?.department?.title}</h1>
                        <p className='home-department-sub-text'>{home?.department?.description}</p>

                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 pb-3">
                        <img src={home?.department?.file_url} alt="" className='img-fluid ms-lg-auto' width={"554"} />
                    </div>


                </div>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 align-items-center pt-5">
                    {home?.department?.department_description?.map((department, index) => <HomeDepartmentsCard
                        key={department.id}
                        department={department}
                    ></HomeDepartmentsCard>)}

                </div>
            </div>
        </div>
    );
};

export default HomeDepartments;