import React from 'react';
import './VisionItemCard.css';

const VisionItemCard = ({ item }) => {
    return (
        <div className='col'>

            <div className="vision-item-card h-100 p-3">
                <img src={item?.icon_url} alt="" className='img-fluid mx-auto pb-2' width={"64"} />
                <div className="card-body">
                    <h5 className="vision-card-title">{item?.title}</h5>
                    <p className="vision-card-detaisl-text">{item?.description}</p>
                </div>
            </div>
        </div>
    );
};

export default VisionItemCard;